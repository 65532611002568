<template>
  <div>
    <div class="backgroundOV"></div>
    <v-card outlined>
      <v-card-title class="titleLarge">Zahlenrechnen</v-card-title>
    </v-card>
    <br />
    <br />
    <v-card outlined>
      <v-card-title class="titleSmaller">Was erwartet Sie im Inhaltsbereich Zahlenrechnen?
      </v-card-title>
      <v-card-text>
        Das Zahlenrechnen beschreibt das Rechnen mit Zahlganzheiten – im
        Vergleich zum Rechnen mit Ziffern, wie es beim schriftlichen Rechnen der
        Fall ist. Insbesondere das Nutzen halbschriftlicher Strategien und somit
        das Ausnutzen von Zahl- und Aufgabenbeziehungen bildet dabei die
        Grundlage, um auch mit größeren Zahlen und in verschiedenen
        Grundrechenarten sicher und flexibel zu rechnen.
        <br />
        Beim halbschriftlichen Rechnen sind drei grundlegende Aspekte von
        Bedeutung. Diese gelten weithin als Grundlage zur Ausbildung eines
        umfassenden Verständnisses des halbschriftlichen Rechnens und deren
        sicherer und flexibler Anwendung. Daher wird im Hintergrundbereich
        zunächst auf diese drei Bereiche eingegangen:
        <ol>
          <li>Strategien des halbschriftlichen Rechnens</li>
          <li>Flexibler Einsatz der Rechenstrategien</li>
          <li>Darstellungsweisen beim halbschriftlichen Rechnen</li>
        </ol>
        Im Anschluss werden an einigen Kinderbeispielen exemplarisch Diagnose-
        und Förderungsaufgaben an Sie gestellt. Den Abschluss bildet eine
        Kompetenzübersicht, sodass noch einmal transparent wird, was im
        Inhaltsbereich Zahlenrechnen zentral ist.
      </v-card-text>
    </v-card>

    <br />

    <br />

    <v-card outlined>
      <v-card-title>Weitere Ressourcen
      </v-card-title>
      <v-card-text>
        Wenn Sie sich weiterführend mit dem Thema Zahlenrechnen auseinandersetzen möchten, können Sie auch folgende
        Angebote der Plattform KIRA nutzen.
        <br />
        <ul style="list-style-type: none; padding-left: 10px">
          <li style=" margin: 8px 0px;"><a href="https://kira.dzlm.de/node/618" target="_blank"><v-icon
                left>mdi-open-in-new</v-icon>KIRA: Halbschriftliches Rechnen</a></li>
          <li style=" margin: 8px 0px;"><a href="https://kira.dzlm.de/node/802" target="_blank"><v-icon
                left>mdi-open-in-new</v-icon>KIRA: Halbschriftlich</a></li>
          <li style=" margin: 8px 0px;"><a href="https://kira.dzlm.de/node/127" target="_blank"><v-icon
                left>mdi-open-in-new</v-icon>KIRA: Flexibles Rechnen</a></li>
        </ul>
      </v-card-text>
    </v-card>

    <v-row no-gutters justify="center" align="center">
      <p class="white--text" style="z-index: 1; padding-top: 15px">
        Im unteren Bereich finden Sie die Navigation.
      </p>
    </v-row>
    <v-row no-gutters justify="center" align="center">
      <v-icon x-large class="white--text">mdi-arrow-down</v-icon>
    </v-row>

    <AppBottomNavHR next="/zahlenrechnen/hintergrundwissen" />
  </div>
</template>

<script>
import AppBottomNavHR from "@/common/AppBottomNavHR";
export default {
  components: {
    AppBottomNavHR,
  },
};
</script>
<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}

#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
